/**
 * @name: 数据统计-设备订单量统计接口文件
 * @author: itmobai
 * @date: 2024-08-01 10:50
 * @description： 数据统计-设备订单量统计接口文件
 * @update: 2024-08-01 10:50
 */
import api from '@/request/api'
import { IDeviceOrderParams, IDeviceOrder } from './types'
import { IPageRes } from '@/apis/page'

export const deviceOrderPageApi = (params: IDeviceOrderParams) => api.get<IPageRes<IDeviceOrder[]>>('/admin/device/dataOrderQuantityStatistics', params)

export const deviceOrderNumApi = (params: IDeviceOrderParams) => api.get('/admin/device/dataOrderQuantityStatistics/sum', params)

export const deviceOrderExportApi = (params: IDeviceOrderParams) => api.get('/admin/device/dataOrderQuantityStatistics/export', params, 'blob')
